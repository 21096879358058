export default class ErpInventoryEntity {
    static getEntityData(elem: any) {
        return {
            id: elem.querySelector("#erp_inventory_id") ? (elem.querySelector("#erp_inventory_id") as HTMLInputElement).value : null,
            
            title: (elem.querySelector("#erp_inventory_title") as HTMLInputElement).value,
            // @ts-ignore
            warehouseLocationIds: Array.from(elem.querySelectorAll("#erp_inventory_inventoryOrderWarehouseLocations option:checked")).map(o => o.value)
        }
    }
}